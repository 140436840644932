import React, { useState, useEffect } from 'react';
import Link from '../components/Link';
import { format } from 'date-fns';

export default ({ blogList }) => {
    const [visibleCount, setVisibleCount] = useState(6); // Default for desktop
    const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		if (window.innerWidth <= 768) {
			setIsMobile(true);
			setVisibleCount(3); // 3 items on mobile
		} else {
			setIsMobile(false);
			setVisibleCount(6); // 6 items on desktop
		}
	}, []);

    const loadMore = () => {
        // Increase the visible count based on screen size
        if (isMobile) {
            setVisibleCount(prevCount => prevCount + 3); // Load 3 more items on mobile
        } else {
            setVisibleCount(prevCount => prevCount + 6); // Load 6 more items on desktop
        }
    };

    if (!blogList || blogList.length === 0) {
        return <p>No blog posts available.</p>;
    }

    // Slice the blogList to show only the visibleCount number of posts
    const visiblePosts = blogList.slice(0, visibleCount);

    return (
		<>
			{visiblePosts.map(item =>{
				// Find the heroBlog component
				const heroBlog = item.components?.find(
					(component) => component._type === 'heroBlog'
				);
	
				return (
					<div key={item._id} className="col c4--md">
						<div className="image-card">
							<div className="list-image-container">
								<a
									href={`/blog/${item.slug}`}
									aria-label={item.title}>
									<picture>
										<source
											srcSet={
												item.desktopBlogImage?.url ||
												'../images/blank.jpeg'
											}
											media="(min-width: 770px)"
										/>
										<source
											srcSet={
												item.mobileBlogImage?.url ||
												'../images/blank.png'
											}
											media="(max-width: 769px)"
										/>
										<img
											alt={
												item.desktopBlogImage?.alt ||
												item.title
											}
											src={
												item.desktopBlogImage?.url ||
												'../images/blank.jpeg'
											}
										/>
									</picture>
								</a>
							</div>
							<div className="blog-content content">
								<div className="blog-title-section">
									<h3 className="truncate-text">
										{item.title}
									</h3>
									<p className="truncate-text">
										{heroBlog?.subtitle || ''}
									</p>
								</div>
								<p>
									{format(
										new Date(item.postDate),
										'MMMM dd, yyyy',
									)}
								</p>
								<a
									href={`/blog/${item.slug}`}
									className="button--pill--primary"
									aria-label={'Read More ' + item.title}>
									Read More
								</a>
							</div>
						</div>
					</div>
				);})}
			<div className="db x tc mxa seeMore-btn">
				{/* Show the "Load More" button if there are more posts to load */}
				{visibleCount < blogList.length && (
					<button
						onClick={loadMore}
						className="button--primary invert">
						See More
					</button>
				)}
			</div>
		</>
	);
};
