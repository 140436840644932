import React from 'react';

export default ({BlogDesktopImage, BlogMobileImage, title}) => {
	
	if (!BlogDesktopImage?.url && !BlogMobileImage?.url) {
		return null; // Return null if both images are undefined
	}

	return(
	<div className="blog-container grid-container contained mobile-pad-0">
		<div className="row gutter--large--lg align--center mobile-margin-0">
			<div className="col c9--xl mobile-pad-0">
				<div className="row gutter--large--lg align--center mobile-margin-0">
					<div className="col c9--lg mobile-pad-0 ">
						<div className="blogPost-banner">
							<picture>
								<source
									srcSet={`${BlogDesktopImage.url}`}
									media="(min-width: 770px)"
								/>
								<source
									srcSet={`${BlogMobileImage.url}`}
									media="(max-width: 769px)"
								/>
								<img
									alt={BlogDesktopImage.alt?.trim() ? BlogDesktopImage.alt : title}
									src={`${BlogDesktopImage.url}`}
								/>
							</picture>
						</div>
					</div>
					<div className="col c3--lg mobile-pad-0"></div>
				</div>
			</div>
		</div>
	</div>
)};
