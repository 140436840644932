import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import classNames from 'classnames';
import Link from './Link';
export default ({
	title,
	description,
	desktopAdsImage,
	mobileAdsImage,
    adsBackgroudColor,
	cta,
	ctaColor = '#000000',
	ctaBackgroundColor = '#ffffff',
}) => (
	<div className="d-flex mt4 mb4">
		<div className="row gutter--large--lg postSpecial-container d-flex">
			<div className="col c5--lg pr-0 hide show--md mobile-pad-0">
				<div className="postImage-container">
					<picture>
						<img
							alt={desktopAdsImage.alt || 'Desktop - '+title}
							src={`${desktopAdsImage.url}`}
						/>
					</picture>
				</div>
			</div>
			<div className="col c7--lg pl-0 mobile-pad-0">
				<div className="postContent-container" style={{ backgroundColor: adsBackgroudColor }}>
					<div className="pl2 pr2 pl5--md pr5--md">
						<h3>{title}</h3>
						<p className="mb3">
							<BlockContent
								blocks={description}
								serializers={richText}
							/>
						</p>
					</div>

					<div className="postImage-mobile hide--md show mb3">
						<picture>
							<img
								alt={mobileAdsImage.alt || 'Mobile - '+title}
								src={`${mobileAdsImage.url}`}
							/>
						</picture>
					</div>
					{cta && (
						<div className="pl2 pr2 pl5--md pr5--md d-flex">
							<Link
								{...cta}
								style={{
									backgroundColor: ctaBackgroundColor,
									'--borderColor': ctaBackgroundColor,
									color: ctaColor,
								}}
								className={classNames('shop-now', {})}
								aria-label={cta.title}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	</div>
);
