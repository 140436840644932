import React, {useState, useEffect} from 'react';
import Link from '../components/Link';
import Seo from '../components/Seo';
import Helmet from 'react-helmet';
import BlogBox from '../components/BlogBox';


export default ({pageContext, location}) => {

    const { blogList } = pageContext; // Extract blogList from pageContext
    const {seo = {}, header, results = []} = pageContext;
    const metaTitle = seo.metaTitle || 'Clean Medicine Blog | Genexa';
    const openGraphTitle = seo.openGraphTitle || 'Clean Medicine Blog | Genexa';
    const twitterTitle = seo.twitterTitle || 'Clean Medicine Blog | Genexa';

    return (
        <React.Fragment>
            <Helmet title="Search - Genexa"/>
            <Seo
                metaTitle={metaTitle}
                metaDescription={seo.metaDescription}
                metaKeywords={seo.metaKeywords}
                openGraphTitle={openGraphTitle}
                openGraphDescription={seo.openGraphDescription}
                openGraphImage={seo.openGraphImage}
                twitterTitle={twitterTitle}
                twitterDescription={seo.twitterDescription}
                twitterImage={seo.twitterImage}
                pathname={location.pathname}
            />

            <div className="grid-container contained blog-filter-category new-blog-page">
                    <div className="row align--center">
                        <div className="rich-text blog-details mb6 sans--sm blogHero-list">
                            <h1>Inside the Medicine Cabinet</h1>
                            <p>Your source for clean medicine insights and family wellness</p>
                    </div>
                </div>
                <div className='blogList-container'>
                    <div className='row gutter--large--lg'>
                    <BlogBox blogList={blogList} /> {/* Pass blogList to BlogBox */}
            </div>
            </div>
                </div>
        </React.Fragment>
    );
};
