import React from 'react';
import Link from '../components/Link';
import BlockContent from '@sanity/block-content-to-react';
import format from 'date-fns/format';

export default ({
	author = {},
	date,
	title,
	subtitle,
	reviewer = '',
	factChecker = '',
}) => {
	return (
		<div className="postContent-wrapper">
			<h1>{title}</h1>
			{subtitle?.trim() && <h3 className="postHeading-h3">{subtitle}</h3>}
			<div className="dib rich-text author-div">
				<p className="mt0 mb0">
					<strong>Written by </strong>
					<Link
						aria-label={author.name}
						url={`/blog/author/${author.slug}`}>
						{author.name}
					</Link>
					<span> on {format(new Date(date), 'MMMM d, yyyy')}</span>
				</p>
			</div>
			<p className='post-para'></p>
		</div>
	);
};
