import React from 'react';
import Seo from '../components/Seo';
import Link from '../components/Link';
import BlogComponentList from '../components/BlogComponentList';
import BlogSources from '../components/BlogSources';
import BlogLatestPosts from '../components/BlogLatestPosts';
import RelatedBlogPosts from '../components/RelatedBlogPosts';
import BlogShare from '../components/BlogShare';
import HeroBlog from '../components/HeroBlog';

export default ({pageContext, location}) => {
	const {seo = {}, postDate, slug, author = {}, category = {}, components = [], sources = [], relatedBlogPosts = [], blogLatestPosts = [], hideFromSearchEngine} = pageContext;
	const heroBlogObject = components.find(obj => obj._type === 'heroBlog');
	const metaTitle = seo.metaTitle || `${pageContext.title} - Genexa`;
	const openGraphTitle =
		seo.openGraphTitle || `${pageContext.title} - Genexa`;
	const twitterTitle = seo.twitterTitle || `${pageContext.title} - Genexa`;
	const domain = `${location.protocol}//${location.hostname}`;
	const canonicalUrl = `${domain}/blog/${slug}`;

	return (
		<React.Fragment>
			<Seo
				metaTitle={metaTitle}
				metaDescription={seo.metaDescription}
				metaKeywords={seo.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo.openGraphDescription}
				openGraphImage={seo.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo.twitterDescription}
				twitterImage={seo.twitterImage}
				pathname={location.pathname}
				noIndexNoFollow={hideFromSearchEngine}
				canonicalUrl={canonicalUrl}
			/>
			<HeroBlog BlogDesktopImage={pageContext.desktopBlogImage} BlogMobileImage={pageContext.mobileBlogImage} title={heroBlogObject.title} />
			<div className='grid-container contained mobile-pad-0'>
    <div className='row gutter--large--lg align--center side-marg-0'>
        <div className="col c9--xl mobile-pad-0">
            <div className='row gutter--large--lg align--center mobile-margin-0'>
                <div className="col c9--lg">
                <div className='contained postContent mobilePost-style'>
			<BlogComponentList author={author} category={category} title={heroBlogObject.title} subtitle={heroBlogObject.subtitle} components={components} date={postDate} />
			</div>
			</div>
			<RelatedBlogPosts posts={relatedBlogPosts} />
			</div>
			</div>
			</div>
			</div>
		</React.Fragment>
		
	);
};
