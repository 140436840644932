import React from 'react';
import Link from '../components/Link';

export default ({
	_key,
	posts = [],
}) => {
	return (
		<div className="col c3--lg">
			<div className="related-heading">
				<h4 className="">Related Post</h4>
			</div>

			<div className="related-post-wraper">
				{posts.map(
					(
						{
							_id,
							title,
							desktopBlogImage = {},
							mobileBlogImage = {},
							slug,
							category = {},
						},
						index,
					) => (
						<div
							key={_id + '-' + index}
							className="related-post mb2">
							<div className="relatedPost-Image">
							<a
									href={`/blog/${slug}`}
									aria-label={title}>
								<picture>
								<source
									srcSet={
										desktopBlogImage?.url ||
										'/images/blank.jpeg' 
									}
									media="(min-width: 770px)"
									/>
									<source
									srcSet={
										mobileBlogImage?.url ||
										'/images/blank.png' 
									}
									media="(max-width: 769px)"
									/>
									<img
										alt={desktopBlogImage?.alt || title}
										src={
											desktopBlogImage?.url ||
											'/images/blank.jpeg' 
										}
									/>
								</picture>
								</a>
							</div>
							<div className="relatedPost-content">
								<Link className="" url={`/blog/${slug}`}>
									{title}
								</Link>
							</div>
						</div>
					),
				)}
			</div>
		</div>
	);
};
